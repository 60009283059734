import { cn } from '../../../utils/cn';

export function SlideBlockIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
      className={cn('w-3.5 h-3.5 fill-white', props.className)}
    >
      <rect
        x='1.14307'
        y='9.71423'
        width='13.7143'
        height='2.28571'
        rx='1.14286'
      />
      <rect
        x='1.14307'
        y='13.1428'
        width='13.7143'
        height='2.28571'
        rx='1.14286'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.14307 0.571411C1.59078 0.571411 1.14307 1.01913 1.14307 1.57141V7.57141C1.14307 8.1237 1.59078 8.57141 2.14307 8.57141H13.8574C14.4096 8.57141 14.8574 8.1237 14.8574 7.57141V1.57141C14.8574 1.01913 14.4096 0.571411 13.8574 0.571411H2.14307ZM4.57164 7.23808H10.7431L13.4868 7.23807L10.744 3.23807L8.91489 5.01633L8.00021 3.90474L4.57164 7.23808ZM4.91494 4.5714C5.48301 4.5714 5.94351 4.12369 5.94351 3.5714C5.94351 3.01912 5.48301 2.5714 4.91494 2.5714C4.34688 2.5714 3.88637 3.01912 3.88637 3.5714C3.88637 4.12369 4.34688 4.5714 4.91494 4.5714Z'
      />
    </svg>
  );
}
