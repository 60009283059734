import { cn } from '../../../utils/cn';

export function FillInTheBlanksBlockIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={cn('w-3.5 h-3.5 fill-white', props.className)}
    >
      <rect x='1.14307' y='3.42859' width='13.7143' height='3.42853' />
      <path d='M1.14307 9.1427H5.71449V12.5712H1.14307V9.1427Z' />
      <rect x='6.85693' y='11.4285' width='8' height='1.14285' rx='0.571423' />
    </svg>
  );
}
