import { cn } from '../../../utils/cn';

export function SparkifactBlockIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
      className={cn('w-3.5 h-3.5 fill-white', props.className)}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.67828 4.74482C4.04793 5.01779 4.10786 5.51569 3.81214 5.8569L1.95481 7.99998L3.81214 10.1431C4.10786 10.4843 4.04793 10.9822 3.67828 11.2551C3.30863 11.5281 2.76924 11.4728 2.47352 11.1316L0.187825 8.49424C-0.0626084 8.20528 -0.0626084 7.79468 0.187825 7.50572L2.47352 4.86838C2.76924 4.52717 3.30863 4.47185 3.67828 4.74482Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.3217 4.74482C11.9521 5.01779 11.8921 5.51569 12.1879 5.8569L14.0452 7.99998L12.1879 10.1431C11.8921 10.4843 11.9521 10.9822 12.3217 11.2551C12.6914 11.5281 13.2308 11.4728 13.5265 11.1316L15.8122 8.49424C16.0626 8.20528 16.0626 7.79468 15.8122 7.50572L13.5265 4.86838C13.2308 4.52717 12.6914 4.47185 12.3217 4.74482Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.69001 12.3415C5.27899 12.1067 5.116 11.6184 5.32595 11.251L9.41243 4.09966C9.62238 3.73224 10.1258 3.62479 10.5368 3.85966C10.9478 4.09452 11.1108 4.58277 10.9008 4.95018L6.81437 12.1015C6.60442 12.4689 6.10102 12.5764 5.69001 12.3415Z'
      />
    </svg>
  );
}
