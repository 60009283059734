import { cn } from '../../../utils/cn';

export function ResultsBlockIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 14 14'
      className={cn('w-3.5 h-3.5 fill-white', props.className)}
    >
      <path d='M6 2a1 1 0 0 1 2 0v7.4a.6.6 0 0 1-.6.6h-.8a.6.6 0 0 1-.6-.6zM2 4a1 1 0 0 1 2 0v5.4a.6.6 0 0 1-.6.6h-.8a.6.6 0 0 1-.6-.6zM11 4a1 1 0 0 0-1 1v4.4a.6.6 0 0 0 .6.6h.8a.6.6 0 0 0 .6-.6V5a1 1 0 0 0-1-1M1.6 11a.6.6 0 1 0 0 1.2h10.8a.6.6 0 1 0 0-1.2z'></path>
    </svg>
  );
}
