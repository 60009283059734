import { EnumsSlideLayout } from '@lp-lib/api-service-client/public';
import { type BlockFields, BlockType } from '@lp-lib/game';

import { ShuffleIcon } from '../ShuffleIcon';
import { TBDIcon } from '../TBDIcon';
import { AIChatBlockIcon } from './AIChatBlockIcon';
import { CreativePromptBlockIcon } from './CreativePromptBlockIcon';
import { DrawingPromptBlockIcon } from './DrawingPromptBlockIcon';
import { DrawToWinBlockIcon } from './DrawToWinBlockIcon';
import { FillInTheBlanksBlockIcon } from './FillInTheBlanksBlockIcon';
import { FreeFormBlockIcon } from './FreeFormBlockIcon';
import { GuessWhoBlockIcon } from './GuessWhoBlockIcon';
import { HeadToHeadBlockIcon } from './HeadToHeadBlockIcon';
import { HiddenPictureBlockIcon } from './HiddenPictureBlockIcon';
import { IcebreakerBlockIcon } from './IcebreakerBlockIcon';
import { InstructionBlockIcon } from './InstructionBlockIcon';
import { JeopardyBlockIcon } from './JeopardyBlockIcon';
import { MemoryMatchBlockIcon } from './MemoryMatchBlockIcon';
import { MultipleChoiceBlockIcon } from './MultipleChoiceBlockIcon';
import { OverRoastedBlockIcon } from './OverRoastedBlockIcon';
import { PuzzleBlockIcon } from './PuzzleBlockIcon';
import { QuestionBlockIcon } from './QuestionBlockIcon';
import { RapidBlockIcon } from './RapidBlockIcon';
import { ResultsBlockIcon } from './ResultsBlockIcon';
import { RoleplayBlockIcon } from './RoleplayBlockIcon';
import { RoundRobinQuestionBlockIcon } from './RoundRobinQuestionBlockIcon';
import { ScoreboardBlockIcon } from './ScoreboardBlockIcon';
import { SlideBlockIcon } from './SlideBlockIcon';
import { SlideshowBlockIcon } from './SlideshowBlockIcon';
import { SparkifactBlockIcon } from './SparkifactBlockIcon';
import { SpotlightBlockIcon } from './SpotlightBlockIcon';
import { SwipeToWinBlockIcon } from './SwipeToWinBlockIcon';
import { TeamRelayBlockIcon } from './TeamRelayBlockIcon';
import { TitleBlockIcon } from './TitleBlockIcon';
import { VideoBlockIcon } from './VideoBlockIcon';

export * from './AIChatBlockIcon';
export * from './CreativePromptBlockIcon';
export * from './DrawingPromptBlockIcon';
export * from './MemoryMatchBlockIcon';
export * from './MultipleChoiceBlockIcon';
export * from './PuzzleBlockIcon';
export * from './QuestionBlockIcon';
export * from './RapidBlockIcon';
export * from './RoundRobinQuestionBlockIcon';
export * from './ScoreboardBlockIcon';
export * from './SpotlightBlockIcon';
export * from './TeamRelayBlockIcon';
export * from './TitleBlockIcon';
export * from './HeadToHeadBlockIcon';

export const BlockIconMap = {
  [BlockType.QUESTION]: QuestionBlockIcon,
  [BlockType.CREATIVE_PROMPT]: CreativePromptBlockIcon,
  [BlockType.RAPID]: RapidBlockIcon,
  [BlockType.SCOREBOARD]: ScoreboardBlockIcon,
  [BlockType.SPOTLIGHT]: SpotlightBlockIcon,
  [BlockType.TEAM_RELAY]: TeamRelayBlockIcon,
  [BlockType.RANDOMIZER]: ShuffleIcon,
  [BlockType.MULTIPLE_CHOICE]: MultipleChoiceBlockIcon,
  [BlockType.MEMORY_MATCH]: MemoryMatchBlockIcon,
  [BlockType.PUZZLE]: PuzzleBlockIcon,
  [BlockType.ROUND_ROBIN_QUESTION]: RoundRobinQuestionBlockIcon,
  [BlockType.TITLE_V2]: TitleBlockIcon,
  [BlockType.INSTRUCTION]: InstructionBlockIcon,
  [BlockType.OVERROASTED]: OverRoastedBlockIcon,
  [BlockType.SPOTLIGHT_V2]: SpotlightBlockIcon,
  [BlockType.DRAWING_PROMPT]: DrawingPromptBlockIcon,
  [BlockType.HIDDEN_PICTURE]: HiddenPictureBlockIcon,
  [BlockType.AI_CHAT]: AIChatBlockIcon,
  [BlockType.ICEBREAKER]: IcebreakerBlockIcon,
  [BlockType.GUESS_WHO]: GuessWhoBlockIcon,
  [BlockType.MARKETING]: TBDIcon,
  [BlockType.JEOPARDY]: JeopardyBlockIcon,
  [BlockType.HEAD_TO_HEAD]: HeadToHeadBlockIcon,
  [BlockType.SLIDE]: SlideBlockIcon,
  [BlockType.CODE_CANVAS]: SlideBlockIcon,
  [BlockType.DRAW_TO_WIN]: DrawToWinBlockIcon,
  [BlockType.ROLEPLAY]: RoleplayBlockIcon,
  [BlockType.SPARKIFACT]: SparkifactBlockIcon,
  [BlockType.FILL_IN_THE_BLANKS]: FillInTheBlanksBlockIcon,
  [BlockType.SWIPE_TO_WIN]: SwipeToWinBlockIcon,
  [BlockType.SCENARIO]: RoleplayBlockIcon,
  [BlockType.RESULTS]: ResultsBlockIcon,
} as const;

const BlockIconCustomizeMap = {
  [EnumsSlideLayout.SlideLayoutBigMedia]: VideoBlockIcon,
  [EnumsSlideLayout.SlideLayoutFreeForm]: FreeFormBlockIcon,
  [EnumsSlideLayout.SlideLayoutSlideshow]: SlideshowBlockIcon,
} as const;

function isCustomizedBlockIcon(
  input: EnumsSlideLayout
): input is keyof typeof BlockIconCustomizeMap {
  return input in BlockIconCustomizeMap;
}

export function BlockIcon(
  props: React.SVGProps<SVGSVGElement> & { blockType: BlockType }
): JSX.Element | null {
  const { blockType, ...rest } = props;

  if (!blockType) return null;

  const Icon = BlockIconMap[blockType];

  if (!Icon) return null;

  return <Icon {...rest} />;
}

function OverriddenBlockIcon(
  props: React.SVGProps<SVGSVGElement> & {
    blockType: BlockType;
    fields: BlockFields | { layout: EnumsSlideLayout };
  }
): JSX.Element | null {
  const { blockType, fields, ...rest } = props;

  if (
    'layout' in fields &&
    fields.layout &&
    isCustomizedBlockIcon(fields.layout)
  ) {
    const Icon = BlockIconCustomizeMap[fields.layout];
    return <Icon {...rest} />;
  } else {
    // Otherwise fallback to the default logic
    return <BlockIcon blockType={blockType} {...rest} />;
  }
}

const sparkBlockIconBgMap: { [key in BlockType]?: string } = {
  [BlockType.SLIDE]: 'bg-blue-005',
  [BlockType.CODE_CANVAS]: 'bg-blue-005',
  [BlockType.ROLEPLAY]: 'bg-[#E96B24]',
  [BlockType.JEOPARDY]: 'bg-[#E96B24]',
  [BlockType.SPARKIFACT]: 'bg-[#E96B24]',
  [BlockType.SCENARIO]: 'bg-[#E96B24]',
  [BlockType.RESULTS]: 'bg-[#E96B24]',
};

export function SparkBlockIcon(props: {
  className?: string;
  blockType: BlockType;
  fields?: BlockFields | { layout: EnumsSlideLayout };
  variant?: 'bg' | 'no-bg';
  bgColor?: string;
}) {
  const { className, variant = 'bg' } = props;
  const bgColor =
    props.bgColor ?? sparkBlockIconBgMap[props.blockType] ?? 'bg-[#00D0C4]';

  if (variant === 'bg') {
    return (
      <div className={className ?? 'w-6 h-6'}>
        <div
          className={`w-full h-full flex items-center justify-center rounded-md ${bgColor} text-white`}
        >
          {props.fields ? (
            <OverriddenBlockIcon
              className='w-2/3 h-2/3 fill-current block-v2-icon'
              blockType={props.blockType}
              fields={props.fields}
            />
          ) : (
            <BlockIcon
              className='w-2/3 h-2/3 fill-current block-v2-icon'
              blockType={props.blockType}
            />
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className={className ?? 'w-6 h-6'}>
        {props.fields ? (
          <OverriddenBlockIcon
            className='w-full h-full fill-current'
            blockType={props.blockType}
            fields={props.fields}
          />
        ) : (
          <BlockIcon
            className='w-full h-full fill-current'
            blockType={props.blockType}
          />
        )}
      </div>
    );
  }
}
