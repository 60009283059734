import { cn } from '../../../utils/cn';

export function SlideshowBlockIcon(
  props: React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
      className={cn('w-3.5 h-3.5 fill-current', props.className)}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.75634 2.28571C1.86521 2.28571 1.14281 3.02792 1.14281 3.94348V8.9168C1.14281 9.83229 1.8651 10.5745 2.75613 10.5746C2.75613 11.4901 3.47842 12.2322 4.36946 12.2323C4.36946 13.1479 5.09186 13.8901 5.98299 13.8901H13.2439C14.135 13.8901 14.8574 13.1479 14.8574 12.2323V7.25902C14.8574 6.34353 14.1351 5.60136 13.2441 5.60125C13.2441 4.68576 12.5218 3.94359 11.6308 3.94348C11.6308 3.02792 10.9084 2.28571 10.0172 2.28571H2.75634ZM11.6308 4.77236C12.0762 4.77248 12.4373 5.14354 12.4373 5.60125V10.5746C12.4373 11.0323 12.0761 11.4035 11.6306 11.4035H4.36967C3.9241 11.4035 3.5629 11.0323 3.5629 10.5746L10.0172 10.5746C10.9084 10.5746 11.6308 9.83236 11.6308 8.9168V4.77236ZM11.6306 12.2323H5.17623C5.17623 12.6901 5.53743 13.0612 5.98299 13.0612H13.2439C13.6895 13.0612 14.0507 12.6901 14.0507 12.2323V7.25902C14.0507 6.80131 13.6896 6.43025 13.2441 6.43014V10.5746C13.2441 11.4901 12.5217 12.2323 11.6306 12.2323Z'
      />
    </svg>
  );
}
