import { cn } from '../../../utils/cn';

export function SwipeToWinBlockIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
      className={cn('w-3.5 h-3.5 fill-white', props.className)}
    >
      <path d='M2.45661 5.18096L6.07062 11.5613C6.07062 11.5613 1.53467 12.0961 2.88007 14.4871C6.19524 13.5511 8.44315 16.781 10.3573 15.8189L12.9248 14.1445C14.0113 13.4383 13.8322 11.8871 13.249 10.8769L11.5539 7.88717C11.3251 7.49858 10.8851 7.31786 10.4723 7.4432L6.93803 8.56614L4.36087 4.0254C3.61314 2.66219 1.70879 3.88671 2.45661 5.18096Z' />
      <path d='M6.01472 0.133956L4.6616 1.51651V1.51567C4.54119 1.64189 4.54119 1.81827 4.6616 1.94365L6.01472 3.33039C6.16983 3.4516 6.40861 3.46163 6.578 3.35464C6.74739 3.24848 6.79637 3.05623 6.69433 2.90242L5.92083 2.115C7.97908 2.04145 10.0384 2.25042 12.0128 2.73189C12.2435 2.78706 12.4853 2.67839 12.5537 2.48948C12.621 2.30057 12.4894 2.10246 12.2578 2.04646C10.1934 1.54492 8.04115 1.32676 5.8891 1.40033L6.6932 0.578659H6.69422C6.84423 0.424856 6.81362 0.200842 6.62585 0.0779707C6.43809 -0.044901 6.16472 -0.0198479 6.01472 0.133956Z' />
    </svg>
  );
}
